import { cx } from 'lib/cx'
import Image from 'next/image'
import file from 'public/scribbles/bonprix.svg'

export default function BonprixBrand(props: { className?: string }) {
  return (
    <div
      className={cx(props.className, '-my-[.5em] h-[2em] w-[2.3em] relative')}
    >
      <Image src={file} fill alt="bonprix" />
    </div>
  )
}
